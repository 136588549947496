import styled from 'styled-components';

export const Wrapper = styled.div`
	//background-color: var(--header-bg_color);

	.header-nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		gap: 4px;
		border-bottom: 1px dashed #EFB224;
		width: 100%;
		.nav-link {
			flex: 1;
			//height: 95px;
			//background-color: var(--tab-link-bgColor);
			font-size: 13px;
			line-height: 20px;
			font-weight: 500;
			color: var(--tab-link-color);
			display: flex;
			align-items: center;
			justify-content: end;
			flex-direction: column;
			padding-bottom: 12px;
			cursor: pointer;
			.pic {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 73px;
				height: 73px;
				border-radius: 50px;
               border: 1px solid #2F2E48;

			}
			&:hover,
			&.active {
				.pic {
					background-color: var(--tab-link-bgColor-hover);
				}
				//background-color: var(--tab-link-bgColor-hover);
				//color: var(--tab-link-color-hover);
				//	border-bottom: 1px solid #dfdfdf;
				/* svg {
					.path-stroke {
						stroke: #002970;
					}
					.path-color {
						fill: #002970;
					}
					.path-stroke-white {
						stroke: #fff;
					}
					#Path_197,
					#Line_2,
					#Line_5,
					
					#Ellipse_42{
						stroke: #002970;
					}
				
				
				
			} */

				/* &.active .pic,
			&:hover .pic{
				svg {
					#Subtraction_4,
					#Path_550{
						stroke:#002970
					}
				}
			} */
			}
		}
	}
	.slick-slider {
		width: 100%;
		height: 114px;
		max-width: 500px;
		margin: auto;
	}

	.slick-track {
		display: flex;
		gap: 5px;
		margin-left: 0px;
	}

	.slick-prev,
	.slick-next {
		width: 40px;
		height: 40px;
		z-index: 1;
	}

	.slick-prev, .slick-next{
		top: 38%;
	}

	.slick-next {
		right: -34px;
	}
	.slick-prev {
		left: -34px;
	}

	.slick-dots li button:before {
		color: var(--slick-dots-color);
		opacity: 1;
	}
	.slick-dots li.slick-active button:before {
		color: var(--slick-active-color);
	}
	.slick-prev:before,
	.slick-next:before {
		color: var(--slick-active-color);
		font-size: 25px;
	}
`;
