import './DownloadPay2Nomile.css';
import playstor from '../../assets/images/playstor.svg';
import applestor from '../../assets/images/apple.svg';

function Component() {
	return (
		<>
			<div className="DownloadPayWrapper">
				<div className="container">
					<div className=" flex download-wrapper justify-center gap-50">
						<div className="left">
							<h2 className="heading-2">
								One app for <span className="strong">everything</span>
							</h2>
							<p>download Omegaasa Perfect Marketing , and enjoy all the Omegaasa Perfect Marketing  services in just one super app.</p>

							<div>
								<p>Download Omegaasa Perfect Marketing </p>
								<div className="app flex gap-20">
									<img src={playstor} alt="playstor" />
									<img src={applestor} alt="applestor" />
								</div>
							</div>
						</div>
						<div className="right">
							<img src={'https://pay2mobile.fra1.digitaloceanspaces.com/mobile-3.png'} alt="mobile" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Component;
