import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.359157541375!2d77.06702607459687!3d28.52892247572146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1bc51a1e7549%3A0x2bda45a6f04d13fa!2sBijwasan%20Rd%2C%20Delhi!5e0!3m2!1sen!2sin!4v1729235108204!5m2!1sen!2sin"
								height="100%"
								width="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title='map'
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt='location' />
										<p>OFFICE NO-705, NAYAK MOHALLA,.<br/> 
										V.O. BIJWASAN CENTRAL DELHI-110002 </p>
								 	</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt='envelop' />
										<p>
											<strong>Email Id :</strong>omegaasaperfectpvtltd@gmail.com

										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt='mobilebutton' />
										<p>
											<strong>Phone No :</strong>8194091254

										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
