import styled from 'styled-components';
import img from '../../assets/images/bharatBanner.png'

export const Wrapper = styled.div`
    background:  linear-gradient(96deg, #F12C24 0%, #EFB224 100%) 0% 0% no-repeat;

	margin: -30px;
	padding-left: 47px;
	 height: 400px;
	 border-radius: 10px;
  &> div{
	background-image: url(${props => props.img});
	background-position: left bottom ;
	background-repeat: no-repeat;
	.banner-text {
		padding: 76px 40px 20px 0px;
		text-align: end;
		color: var(--banner-color);

		h1 {
			
			font-size: 36px;
			line-height: 42px;
			font-weight: bold;
			
			text-transform: uppercase;
		}
		.flat-text {
			font-size: 26px;
			line-height: 39px;
			font-weight: 500;
			margin-bottom: 13px;
		}
		i {
			margin-top: 82px;
			display: block;
			font-style: normal;
			font-size: 10px;
			line-height: 16px;
		}
	}
  }

	
`;
